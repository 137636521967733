<template>
	<div style="width:100%;">
		<mobile-header active="/mobile/home"></mobile-header>
		<van-button type="info" size="small" style="position: fixed;right: 0;top:230px;z-index: 9999;background-color: #0084C4;"
			@click="goRegister()"
		>
			专家注册
		</van-button>
		<p style="padding-left:15px;margin-bottom: 0;font-weight: 600;">招标公告</p>
		<van-search v-model="searchValue" show-action label="" placeholder="请输入搜索公告关键词" @search="goSearch()">
			<template #action>
				<van-button type="info" size="small" @click="goSearch()" style="vertical-align: middle;background-color: #0084C4;">搜索</van-button>
			</template>
		</van-search>

		<div v-for="(item,index) in tenderInfo" :key="index" @click="goToContent(item)"
			style="padding:15px 15px 0 15px;font-size: 14px;color:#606266">
			<div class="van-multi-ellipsis--l3" style="font-weight: 600;">
				{{item.name}}
			</div>
			<div style="margin-top: 10px;display: flex;">
				<div style="flex: 1;">
					<span>{{way[item.way-1]['name']}}</span>
				</div>
				<div style="flex: 1;text-align: right;padding-right: 10px;">
					{{item.release_time.substr(0,10)}}
				</div>
			</div>
			<van-divider :style="{borderColor: '#DCDFE6'}" />
		</div>
		<p style="text-align: center;color:#0084C4;margin-bottom:50px;" @click="goTender()">查看更多
			<van-icon name="arrow" />
		</p>

		<h4 style="margin-bottom: 0;" class="font2">公司业务</h4>
		<p class="borderBottom"><span>&nbsp;</span></p>
		<div style="display: flex;">
			<div style="flex: 1;padding:0 7px 0 14px;">
				<div style="border:#EBEEF5 1px solid;">
					<div style="background-color:#0084C4;color:#fff;padding:2% 0;text-align: center;">
						<p><i class="iconfont icon-zhaobiao" style="font-size:50px;"></i></p>
						<h4>招标代理</h4>
					</div>
					<div style="padding:0 5px;">
						<p style="color: #595959;font-size: 14px;" class="van-multi-ellipsis--l3">
							核心团队具有十余年招标代理经验，为企事业单位提供制订方案、制订招标文件及发布公告、配合澄清答疑、组织收开评标、发布中标公示和公告等完整招标服务流程。
						</p>
					</div>
				</div>
			</div>

			<div style="flex: 1;padding:0 14px 0 7px;">
				<div style="border:#EBEEF5 1px solid;">
					<div style="background-color:#0084C4;color:#fff;padding:2% 0;text-align: center;">
						<p><i class="iconfont icon-zixun1" style="font-size:50px;"></i></p>
						<h4>招投标咨询</h4>
					</div>
					<div style="padding:0 5px;">
						<p style="color: #595959;font-size: 14px;" class="van-multi-ellipsis--l3">
							招投标咨询服务是为企事业单位提供招标咨询、投标咨询服务，是公司高端服务、高端智库形象塑造的核心业务，其中在招标管理、投标管理、招投标系统开发咨询方面极具竞争优势。
						</p>
					</div>
				</div>
			</div>
		</div>
		<p style="text-align: center;color:#0084C4;margin-bottom:50px;" @click="goWork()">查看更多
			<van-icon name="arrow" />
		</p>

		<h4 style="margin-bottom: 0;" class="font2">业务覆盖地区</h4>
		<p class="borderBottom"><span>&nbsp;</span></p>
		<div style="text-align: center;">
			<div>
				<div style="display: flex;">
					<div
						style="flex:1;border-right:#DCDFE6 1px solid;border-bottom:#DCDFE6 1px solid;padding-bottom:10px;">
						<h2 style="color:#FB8005;">15年+</h2>
						<h5>行业经验</h5>
					</div>
					<div style="flex:1;border-bottom:#DCDFE6 1px solid;padding-bottom:10px;">
						<h2 style="color:#FB8005;">20个+</h2>
						<h5>涉及行业领域</h5>
					</div>
				</div>
				<div style="display: flex;">
					<div style="flex:1;border-right:#DCDFE6 1px solid;padding-top:10px;">
						<h2 style="color:#FB8005;">100个+</h2>
						<h5>涵盖省市</h5>
					</div>
					<div style="flex:1;padding-top:10px;">
						<h2 style="color:#FB8005;">3000个+</h2>
						<h5>业绩案例</h5>
					</div>
				</div>
			</div>
		</div>
		<div style="display: flex;">
			<div style="flex: 1;">
				<china-map></china-map>
			</div>
		</div>
		<!--
		<h4 style="margin-bottom: 0;margin-top:50px;" class="font2">客户案例</h4>
		<p class="borderBottom"><span>&nbsp;</span></p>
		<div class="wrap">
				<ul class="list">
					<li v-for="(item,index) in anli" :key="index"><img :src="item.src"> </li>
				</ul>
		</div>
		-->
		<div style="background-color:#F2F6FC;height: 50px;line-height: 50px;">
			<a href="https://beian.miit.gov.cn" target="_blank" style="text-decoration: none;">
				<p style="text-align: center;color:#909399;font-size: 14px;">粤 ICP 备 19139672号</p>
			</a>
		</div>
	</div>
</template>
<script>
	import chinaMap from "@components/chinamapMobile.vue";
	import Vue from 'vue';
	import mobileHeader from "@components/mobileHeader";
	import {
		Search,
		Button,
		Divider,
		Icon,
		Swipe,
		SwipeItem
	} from 'vant';
	Vue.use(Search);
	Vue.use(Button);
	Vue.use(Divider);
	Vue.use(Icon);
	Vue.use(Swipe);
	Vue.use(SwipeItem);
	export default {
		name: 'home',
		components: {
			mobileHeader,
			chinaMap,
		},
		data() {
			return {
				bannerHeight: '',
				searchValue: '',
				tenderInfo: [],
				/*
				anli: [

					{
						id: 1,
						src: require("@assets/images/homePage/南方电网.png"),
					},
					{
						id: 2,
						src: require("@assets/images/homePage/中国电气装备集团.png"),
					},
					{
						id: 3,
						src: require("@assets/images/homePage/广药集团.png"),
					},
					{
						id: 4,
						src: require("@assets/images/homePage/中通服.png"),
					},
					{
						id: 5,
						src: require("@assets/images/homePage/南沙科金控股.png"),
					},
					
					{
							id: 6,
							src: require("@assets/images/homePage/广州南沙经济开发区商务局.png"),
						},
					{
						id: 7,
						src: require("@assets/images/homePage/中共广州市南沙区委员会组织部.png"),
					},
					{
						id: 8,
						src: require("@assets/images/homePage/广州市规划和自然局南沙分局.png"),
					},
					{
						id: 9,
						src: require("@assets/images/homePage/广州公安局南沙分局.png"),
					},
					{
						id: 10,
						src: require("@assets/images/homePage/南沙消防救援大队.png"),
					},
					{
						id: 11,
						src: require("@assets/images/homePage/华南理工大学.png"),
					},
					{
						id: 12,
						src: require("@assets/images/homePage/广州市海珠区人才服务管理办公室.png"),
					},
					{
						id: 13,
						src: require("@assets/images/homePage/广州市白云区城市管理和综合执法局.png"),
					},
					{
						id: 14,
						src: require("@assets/images/homePage/佛山顺德公安局.png"),
					},
					{
						id: 15,
						src: require("@assets/images/homePage/顺德农业农村局.png"),
					},{
						id: 16,
						src: require("@assets/images/homePage/广州邮政局.png"),
					},
					{
						id: 17,
						src: require("@assets/images/homePage/汇源通.png"),
					},
					{
						id: 18,
						src: require("@assets/images/homePage/广西电力集团logo.png"),
					},
					{
						id: 19,
						src: require("@assets/images/homePage/广州天河骏景花园.png"),
					},
					{
						id: 20,
						src: require("@assets/images/homePage/珠江帝景.png"),
					},
					{
						id: 1,
						src: require("@assets/images/homePage/南方电网.png"),
					},
					{
						id: 2,
						src: require("@assets/images/homePage/中国电气装备集团.png"),
					},
					{
						id: 3,
						src: require("@assets/images/homePage/广药集团.png"),
					},
					{
						id: 4,
						src: require("@assets/images/homePage/中通服.png"),
					},
					{
						id: 5,
						src: require("@assets/images/homePage/南沙科金控股.png"),
					},
					
					{
							id: 6,
							src: require("@assets/images/homePage/广州南沙经济开发区商务局.png"),
						},
					{
						id: 7,
						src: require("@assets/images/homePage/中共广州市南沙区委员会组织部.png"),
					},
					{
						id: 8,
						src: require("@assets/images/homePage/广州市规划和自然局南沙分局.png"),
					},
					{
						id: 9,
						src: require("@assets/images/homePage/广州公安局南沙分局.png"),
					},
					{
						id: 10,
						src: require("@assets/images/homePage/南沙消防救援大队.png"),
					},
					{
						id: 11,
						src: require("@assets/images/homePage/华南理工大学.png"),
					},
					{
						id: 12,
						src: require("@assets/images/homePage/广州市海珠区人才服务管理办公室.png"),
					},
					{
						id: 13,
						src: require("@assets/images/homePage/广州市白云区城市管理和综合执法局.png"),
					},
					{
						id: 14,
						src: require("@assets/images/homePage/佛山顺德公安局.png"),
					},
					{
						id: 15,
						src: require("@assets/images/homePage/顺德农业农村局.png"),
					},{
						id: 16,
						src: require("@assets/images/homePage/广州邮政局.png"),
					},
					{
						id: 17,
						src: require("@assets/images/homePage/汇源通.png"),
					},
					{
						id: 18,
						src: require("@assets/images/homePage/广西电力集团logo.png"),
					},
					{
						id: 19,
						src: require("@assets/images/homePage/广州天河骏景花园.png"),
					},
					{
						id: 20,
						src: require("@assets/images/homePage/珠江帝景.png"),
					},
					
				],
				*/
			}
		},
		mounted: function() {
			this.bus.$emit('headerImg', this.headerImgMobile);
			var that = this;
			this.$axios.post(this.baseUrl + 'admin/tender/searchTender?page=1&limit=5&state=1').then(response => {
				if (response.data.code != '200') {
					console.log(response.data.msg);
				} else {
					that.tenderInfo = response.data.data.res
				}
			}).catch(function(error) {
				window.console.log(error)
			}); //ajax请求失败
		},
		methods: {
			goRegister(){
				this.$router.push('/mobile/register')
			},
			goTender() {
				this.$router.push('/mobile/tender')
			},
			goWork() {
				this.$router.push('/mobile/companyActive')
			},
			goSearch() {
				this.$router.push({
					path: '/mobile/tender',
					query: {
						val: this.searchValue
					}
				})
			},
			goToContent(item) {
				this.$router.push({
					path: '/mobile/tenderContent',
					query: {
						id: window.btoa(window.btoa(item.tender_info_id))
					}
				})
			},
		}
	}
</script>

<style scoped>
	.font2{
		background-image: -webkit-gradient( linear, left top, right top, color-stop(0.3, #22f), color-stop(0.6, #2ff) );
		color: transparent;
		-webkit-background-clip: text;
		text-align: center;
	}
	.borderBottom{
		text-align: center;
		margin:0 0 10px 0;
	}
	.borderBottom span{
		border-bottom:#FF8700 3px solid ;
		display: inline-block;
		width: 20px;
	}
	.wrap {
		overflow: hidden;
		width: 100%;
		margin:25px auto;
	}
	
	.wrap * {
		margin: 0;
		padding: 0;
	}
	
	.list {
		position: relative;
		top: 0px;
		left: 0px;
		width: 4800px;
		height:95px;
		list-style: none;
		animation: mymove 50s infinite linear;
	}
	
	.list li {
		width: 146px;
		height:87px;
		border:2px solid #DCDFE6;
		border:2px solid #DCDFE6;
		border-top:4px solid #DCDFE6;
		border-bottom:4px solid #DCDFE6;
		float: left;
	}
	
	.list img {
		height: 87px;
		width: auto;
	}
	
	.list:hover {
		animation-play-state: paused;
	}
	@-webkit-keyframes mymove {
		0% {
			left: 0px;
		}
		3.3% {
			left: -150px;
		}

		6.6% {
			left: -150px;
		}

		9.9% {
			left: -300px;
		}

		13.2% {
			left: -300px;
		}

		16.5% {
			left: -450px;
		}

		19.8% {
			left: -450px;
		}

		23.1% {
			left: -600px;
		}

		26.4% {
			left: -600px;
		}

		29.7% {
			left: -750px;
		}

		33% {
			left: -750px;
		}

		36.3% {
			left: -900px;
		}

		39.6% {
			left: -900px;
		}

		42.9% {
			left: -1050px;
		}

		46.2% {
			left: -1050px;
		}

		49.5% {
			left: -1200px;
		}

		52.8% {
			left: -1200px;
		}

		56.1% {
			left: -1350px;
		}

		59.4% {
			left: -1350px;
		}

		62.7% {
			left: -1500px;
		}

		66% {
			left: -1500px;
		}

		69.3% {
			left: -1650px;
		}

		72.6% {
			left: -1650px;
		}

		75.9% {
			left: -1650px;
		}
		79.2% {
			left: -1800px;
		}
		82.5% {
			left: -1800px;
		}
		
		85.8% {
			left: -1950px;
		}
		
		89.1% {
			left: -1950px;
		}
		92.4% {
			left: -2250px;
		}
		95.7% {
			left: -2250px;
		}
		
		100% {
			left: -2400px;
		}
	}
</style>
