<template>
  <div class="Page">
    <div id="myChartChina" ref="myChartChina" style="width:100%;height:350px;padding: 0;"></div>
  </div>
  
</template>
 
<script>
import '/src/assets/js/china.js' //引入中国地图数据
export default {
  data() {
    return {
      ifbox:false,
      TipsList:[],
    }
  },
  mounted() {
    
	this.$nextTick(() => {
		this.ChinaMap();
	});
  },
  methods: {
    ChinaMap(){
      var that=this;
      let myChartChina = that.$echarts.init(this.$refs.myChartChina) //这里是为了获得容器所在位置    
        window.onresize = myChartChina.resize;
        myChartChina.setOption({ // 进行相关配置
			backgroundColor: ' #F2F6FC',//地图背景色
			visualMap: {
				show: false,
				min: 0,
				max: 1000,
				text: ['High', 'Low'],
				realtime: false,
				calculable: true,
				inRange: {
					color: ['#11AEE8']
				}
			},
			grid: {
				top:0,//生成的图片和顶部的间
				left:0,//生成的图片和顶部的间
				right:0,//生成的图片和顶部的间
				bottom:0,//生成的图片和顶部的间
				containLabel: true//为ture才会生效
			},
			series: [
				{
					type: 'map',
					map: 'china',
					zoom:'1.23',
					tooltip: {
						show: false
					},
					data: [
						{name: '广东', value: 100},
						{name: '北京', value:100},
						{name: '上海', value: 100},
						{name: '天津', value:100},
						{name: '湖南', value: 100},
						{name: '湖北', value:100},
						{name: '四川', value: 100},
						{name: '山东', value:100},
						{name: '广西', value: 100},
						{name: '云南', value:100},
						
						{name: '福建', value: 100},
						{name: '江西', value:100},
						{name: '浙江', value: 100},
						{name: '安徽', value:100},
						{name: '江苏', value: 100},
						{name: '河南', value:100},
						{name: '辽宁', value: 100},
						{name: '吉林', value:100},
						{name: '黑龙江', value: 100},

						{name: '河北', value: 100},
						{name: '山西', value:100},
						{name: '陕西', value: 100},
						{name: '重庆', value:100},
						{name: '贵州', value: 100},
						{name: '新疆', value:100},
						{name: '海南', value:100},
					],
					label: {
						show: true,
						borderColor:'#fff',
						textStyle: {
							color: '#000',
							fontSize:12
						}
					},
					itemStyle:{
						normal:{
							areaColor:'#93D0F4',
							borderColor: '#fff', // 地图边框颜色
						},
						emphasis:{
							areaColor:'rgba(128, 128, 128, 0)'
						}
					}
				},
			],
        });

    },
   
 
  }
  
}
</script>
<style scoped>
.Page{
  position: relative;
}
.projectList{
  position: absolute;
  bottom:0;
  left:0;
  z-index: 100;
  width: 280px;
  height: 150px;
  background: linear-gradient(#1d4978, #081630);
  display: flex;
  flex-wrap:wrap;
  justify-content:space-around;
  align-items:center;  
}
.projectList>div{
  width: 120px;
  height: 55px;
}
.projectList>div>p{
  color: #fff;
  font-size: 16px;
}
.projectList>div>p>i{
  color: #12baf9;
}
.inputList{
  width: 120px;
  height: 30px;
  background: #1a3c7b;
  border: 2px solid #0f5390;
  margin-top:7px;
  border-radius: 5px;
  letter-spacing:5px;
  color: #65ddd3;
  line-height: 26px;
  text-indent: 1em;
}
 
 
#box{
  width:280px;
  height: 80px;
  background: #026295;
  box-shadow: 0 0 10px #008b9a;
  border: 1px solid #008b9a;
  position: absolute;
  top:10px;
  left: 50%;
  margin-left: -140px;
  color: #fff;
  text-align: center;
}
.box_left{
  width: 239px;
  height: 80px;
  float: left;
}
.box_title{
  width: 238px;
  height: 30px;
  border-bottom: 1px solid #008b9a;
  line-height: 30px;
}
.box_list{
  width: 119px;
  height: 50px;
  float: left;
}
.box_lists{
  border-left: 1px solid #008b9a;
}
.box_list>p:nth-child(1){
  font-size: 20px;
  line-height: 28px;
  color: yellow;
}
.box_lists>p:nth-child(1){
  color: rgb(111, 185, 234);
}
 
.box_right{
  width: 39px;
  height: 80px;
  border-left: 1px solid #008b9a;
  float: left;
}
.box_right i{
  line-height: 80px;
}
 
 
 
.dilog {
  /* width: 180px; */
  height: 80px;
  background: black;
  position: absolute;
  border-radius: 5px;
  border: solid 1px rgb(0, 139, 154);
  z-index: 11111;
  display: flex;
  box-shadow: 0 0 10px rgb(0, 139, 154);
}
.dilog_title {
  text-align: center;
  height: 30px;
  border-bottom: solid 1px grey;
  line-height: 30px;
  color: white;
}
.box_Tips{
  width:96%;
  height: 20px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 14px;
  color: #fff;
}
.box_Tips>p{
  white-space: nowrap;
  position: absolute;
  top: 0px;
  left: 0;
  animation:mymove 30s infinite;
}
@keyframes mymove
{
from {left: 70%;}
to {left: 0%;}
}
</style>
